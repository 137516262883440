<template>
  <div>
    <component
      :is="MTC_UI_CONTROL[mtc.ui_control]"
      ref="component"
      :measurement-type-component="mtc"
      :measurement-type-id="measurementTypeId"
      :existing-measurement="existingMeasurement"
      :existing-measurements="currentExistingMeasurements"
      :can-edit="canEdit"
      @precheck-started="onPrecheckStarted"
      @precheck-finished="onPrecheckFinished"
      @has-alerts="onHasAlerts"
    />
    <MeasurementTypeComponent
      v-for="follow_up in getFollowups"
      :key="follow_up.measurement_type_id"
      :mtc="follow_up"
      :measurement-type-id="measurementTypeId"
      :existing-measurement="existingMeasurement"
      :current-existing-measurements="currentExistingMeasurements"
      :on-has-alerts="onHasAlerts"
      :on-precheck-started="onPrecheckStarted"
      :on-precheck-finished="onPrecheckFinished"
      :can-edit="canEdit"
    />
  </div>
</template>

<script>
  import TextWithLabel from "./measurement_form_fields/TextWithLabel.vue";
  import Slider from "./measurement_form_fields/Slider.vue";
  import Stepper from "./measurement_form_fields/Stepper.vue";
  import SelectInput from "./measurement_form_fields/Select.vue";
  import OptionsList from "./measurement_form_fields/OptionsList.vue";
  import { useMeasurementFieldsStore } from "@/store/pinia/measurementFields";
  import { mapActions } from "pinia";

  export default {
    name: "MeasurementTypeComponent",
    components: {
      OptionsList,
      SelectInput,
      Stepper,
      Slider,
      TextWithLabel,
    },
    props: {
      mtc: { type: Object, default: null },
      measurementTypeId: { type: Number, default: null },
      onHasAlerts: { type: Function, default: () => null },
      onPrecheckStarted: { type: Function, default: () => null },
      onPrecheckFinished: { type: Function, default: () => null },
      existingMeasurement: { type: Object, default: null },
      currentExistingMeasurements: { type: Object, default: null },
    },

    data() {
      return {
        isMounted: false,
      };
    },
    computed: {
      getFollowups() {
        if (!this.isMounted) return [];

        return this.$refs.component.followUpMtcs;
      },

      canEdit() {
        if (!this.existingMeasurement) return true;

        const measurementState =
          this.$store.state[`measurement${this.measurementTypeId}`];

        if (measurementState) return measurementState.editable;

        return true;
      },
    },

    created() {
      this.MTC_UI_CONTROL = {
        options_list: "OptionsList",
        select: "SelectInput",
        stepper: "Stepper",
        slider: "Slider",
        text_with_label: "TextWithLabel",
      };

      this.PROMPT_TYPES = {
        concerning: "concerning",
        not_concerning: "not_concerning",
      };
    },

    mounted() {
      this.isMounted = true;

      if (this.existingMeasurement && this.mtc.has_follow_ups) {
        this.$store.commit(`measurement${this.measurementTypeId}/setReadOnly`);
      }

      if (this.$refs.component) {
        this.addMeasurementField({
          measurementTypeId: this.measurementTypeId,
          field: this.$refs.component,
        });
      }
    },

    unmounted() {
      this.removeMeasurementField({
        measurementTypeId: this.measurementTypeId,
        id: this.mtc.id,
      });
    },

    methods: {
      ...mapActions(useMeasurementFieldsStore, [
        "addMeasurementField",
        "removeMeasurementField",
      ]),
    },
  };
</script>
