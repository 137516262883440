import debounce from "lodash/debounce";
import { useCalendarDatesStore } from "@/store/pinia/calendarDates";
import { mapState } from "pinia";

const PRECHECK_DELAY = 500;

export const measurementPrecheck = {
  props: ["measurementTypeId"],
  data() {
    return {
      isBaselineConcerning: false,
      isThresholdConcerning: false,
      isChangeThresholdConcerning: false,
      followUpMtcs: [],
    };
  },

  methods: {
    checkValueConditionalPrompt: debounce(async function (value) {
      if (value === null || value === undefined) {
        this.$emit("precheckFinished");
        return;
      }

      this.$emit("precheckStarted");
      const { userId } = this.$store.state;

      const params = {
        measurement: {
          durin_user_id: userId,
          timestamp: this.startDate.toISOString(),
          measurement_type_id: this.measurementTypeId,
          measurement_value: {
            data: value,
            measurement_type_component_id: this.measurementTypeComponentId,
            database_units: this.databaseUnits,
          },
        },
      };

      const response = await this.$api.post(
        `/self_reporter/episodes/${this.currentEpisodeId}/measurements/precheck`,
        { ...params }
      );

      const {
        data: {
          is_baseline_concerning = false,
          is_threshold_concerning = false,
          is_change_threshold_concerning = false,
          follow_up_mtcs = [],
        },
      } = response;

      this.isBaselineConcerning = is_baseline_concerning;
      this.isThresholdConcerning = is_threshold_concerning;
      this.isChangeThresholdConcerning = is_change_threshold_concerning;
      this.followUpMtcs = follow_up_mtcs;
      this.$emit("precheckFinished");
    }, PRECHECK_DELAY),
  },

  computed: {
    ...mapState(useCalendarDatesStore, ["startDate"]),
    isConcerning: function () {
      return (
        this.isBaselineConcerning ||
        this.isThresholdConcerning ||
        this.isChangeThresholdConcerning
      );
    },
  },

  watch: {
    isConcerning(newValue) {
      this.concerning = newValue;
      this.$emit("hasAlerts", newValue);
    },
    fieldValue(newValue) {
      this.$emit("precheckStarted");
      this.checkValueConditionalPrompt(newValue);
    },
  },
};
